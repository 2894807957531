@use '@angular/material' as mat;
@use '@slp/build-conventions' as build;

@import 'material-icons/iconfont/material-icons.css';
@import 'swiper/swiper-bundle.css';

@import 'bootstrap/scss/bootstrap';
@import 'variables';
@import 'mixins';

@import 'styles-general';
@import 'styles-structure';
@import 'styles-dialog';
@import 'styles-table';
@import 'styles-form';
@import 'styles-print';
@import './assets/styles/layout';
@import './mat-bottom-sheet.override';

@import 'node_modules/@shared/ui-components/lib/styles/styles.scss';

@import 'slm-overrides';

// @slp/build-conventions setup below.
@include build.typography-fontsource();

body {
    @include build.all-tokens();

    @include build.typography-styles();
    @include build.themes-all-styles();

    // Defaults to blue theme
    @include build.themes-blue-variables();
}
