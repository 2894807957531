@use '@angular/material' as mat;

html,
body,
form,
fieldset,
table,
tr,
td,
img,
h1,
h2,
h3,
h4,
h5,
h6,
input,
button,
span,
p,
div,
article {
    font-family: 'Poppins', sans-serif;
}

:root {
    font-size: 16px;
}

body {
    overflow-x: hidden;
    background-color: $body-bg !important;

    &.test {
        [class*='tst-'] {
            border: 1px solid #03a900 !important;
            background-color: #b0f0af !important;
            color: #323232 !important;
        }

        [class*='-cmp'] {
            display: block;
        }

        .test-case-error {
            background-color: #fc6f82 !important;
            border: 2px dashed #222 !important;
            color: #000 !important;
            opacity: 1;
        }
    }
}

.app-inner-container {
    @include app-content();
}

// ***************** DPI Scaling *************** //
/* 1.25 dpr */
@include dpr1_25 {
    :root {
        @include font-size(0.875);
    }
}

/* 1.5 dpr */
@include dpr1_5 {
    :root {
        .app-page-container {
            .app-content-wrapper {
                @include large-width {
                    max-width: 100% !important;
                }

                .statistics-page {
                    .statistics-container {
                        .tabs-filter-container {
                            .header-title {
                                font-size: 1.5rem;
                            }
                        }

                        .stats-insight-left-col {
                            padding: 1rem 0;

                            .left-col-title {
                                font-size: 1.5rem !important;
                            }

                            .left-col-text {
                                font-size: 1.1rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.d-flex {
    display: flex !important;
}

// inherited from material 2

.slm-mat-select-locations .mat-mdc-option.toggle-all .mat-pseudo-checkbox,
.slm-mat-select-multiple .mat-option.toggle-all .mat-pseudo-checkbox,
.slm-mat-filter-select .mat-option.toggle-all .mat-pseudo-checkbox,
.todo-list-loc-filter .mat-option.toggle-all .mat-pseudo-checkbox {
    display: none !important;
}

.slm-mat-select-locations .mat-mdc-option.mat-option-disabled .mat-option-text,
.slm-mat-select-multiple .mat-option.mat-option-disabled .mat-option-text,
.slm-mat-filter-select .mat-option.mat-option-disabled .mat-option-text,
.todo-list-loc-filter .mat-option.mat-option-disabled .mat-option-text {
    color: #bebebe !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
.slm-mat-select-multiple .mat-mdc-option.mat-option-disabled .mat-option-text {
    line-height: 1em !important;

    .disable-text {
        color: #f00 !important;
        font-size: 10px;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.  */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.  */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.  */
.slm-mat-select-location {
    min-width: calc(100% + 26px) !important;
    box-shadow: 0 1px 3px $req-form-2-location-shadow !important;
    @include media(xs, sm) {
        min-width: calc(100% + 22px) !important;
    }

    &.mat-primary .mat-mdc-option {
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
        &.mat-selected:not(.mat-option-disabled) {
            color: $req-form-2-location-option-text;
        }

        &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
            background: $input-option-selected-bg !important;
        }
    }

    &.mat-mdc-select-panel {
        max-height: 11rem !important;
        padding: 8px 1px 1px 1px;
        position: absolute;
        top: 2.188rem;
        margin-left: -13px;
        @include media(xs, sm) {
            max-height: 12.571rem !important;
            top: 2.5rem;
            margin-left: -11px;
        }

        .mat-mdc-option {
            padding: 0 0.75rem !important;
            font-size: 0.875rem !important;
            @include media(xs, sm) {
                padding: 0 0.857rem !important;
                font-size: 1rem !important;
            }
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
    .mat-mdc-option.mat-option-disabled {
        white-space: normal !important;
        line-height: 4em;
        height: 4em;
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
        .mat-option-text {
            color: $my-client-grey-dark !important;
            line-height: 1em !important;
            white-space: nowrap !important;

            .disable-text {
                color: $my-client-grey-dark !important;
                font-size: 0.625rem;
                white-space: normal !important;

                a {
                    color: $req-form-2-location-border-field !important;
                    text-decoration: underline;
                    font-size: 0.625rem;
                }
            }
        }
    }
}

.slm-mat-select-locations
    .mat-option.toggle-all
    span
    .mat-mdc-checkbox.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-inner-container
    .mat-checkbox-background,
.slm-mat-select-multiple
    .mat-option.toggle-all
    span
    .mat-checkbox.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-inner-container
    .mat-checkbox-background,
.slm-mat-filter-select
    .mat-option.toggle-all
    span
    .mat-checkbox.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-inner-container
    .mat-checkbox-background,
.todo-list-loc-filter
    .mat-option.toggle-all
    span
    .mat-checkbox.mat-checkbox-checked
    .mat-checkbox-layout
    .mat-checkbox-inner-container
    .mat-checkbox-background {
    background-color: #0063d1;
}

.slm-mat-select-locations .mat-mdc-option .mat-option-text,
.slm-mat-select-multiple .mat-option .mat-option-text,
.slm-mat-filter-select .mat-option .mat-option-text,
.todo-list-loc-filter .mat-option .mat-option-text {
    width: 94%;
    color: #0063d1;
}

.post-widget-card {
    .sp-form-header-container {
        @include wpm-gradient-reverse();
    }
}

.share-best-review-widget-card {
    .sp-form-header-container {
        @include wpm-gradient-reverse();
    }
}

.review-list-item.not-read {
    border-left-style: solid;
    border-left-width: 6px;
    border-left-color: $rev-list-item-not-read;
}

.quick-link-header-conatainer {
    @include wpm-gradient-reverse();
}

.create-new-btn-holder {
    .sp-form-header-container {
        @include wpm-gradient-reverse();
    }
}

.user-menu-dropdown {
    min-width: 20.625rem !important;
    max-width: 22.5rem !important;
    box-shadow: 0px 3px 10px 4px $black-alpha-20 !important;

    .mat-mdc-menu-content {
        padding-top: 0;
        padding-bottom: 0;

        .mat-mdc-menu-item.hidden {
            display: none;
        }

        .mat-mdc-menu-item {
            border-top: 1px solid $head-user-menu-border;
            text-transform: lowercase;
            padding: 0 3em;
            color: $head-user-menu-text;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 3rem;
            height: 3rem;
            transition: all 0.3s ease-out;

            &::first-letter {
                text-transform: capitalize;
            }

            mat-icon {
                display: none;
            }

            &:hover:not([disabled]) {
                color: $head-user-menu-hover;
                transition: all 0.3s ease-in;
            }

            &:last-child {
                border-top: 2px dotted rgba(0, 0, 0, 0.3);
                text-align: right;
                color: $head-user-menu-logout;
                text-decoration: underline;
            }

            span {
                @include font-size(1);
            }
        }
    }
}

.white-bg {
    .app-page-container {
        background: white;

        .app-content-wrapper {
            background-color: white;
        }
    }
}

.custom-menu-dropdown {
    min-width: 34.375rem !important;
    max-width: 34.375rem !important;
    box-shadow: none;
    border: 1px solid #b4b4b4;
    border-top: none;

    .mat-mdc-menu-content {
        padding-top: 0;
        padding-bottom: 0;

        .mat-mdc-menu-item {
            border-top: 1px solid $head-user-menu-border;
            padding: 0 3em;
            color: white;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1rem;
            line-height: 3rem;
            height: 3rem;
            transition: all 0.3s ease-out;
            cursor: default;

            &::first-letter {
                text-transform: capitalize;
            }

            mat-icon {
                display: none;
            }

            span {
                @include font-size(1);
            }
        }
    }

    .mat-mdc-input-element {
        caret-color: white;
    }

    .mat-form-field-invalid .mat-mdc-input-element {
        caret-color: white;
    }

    .search-faq {
        max-height: 4rem;
        min-height: 4rem;
        padding-top: 1rem;
        padding-left: 1rem;
        @include wpm-gradient();
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
    .mat-form-field-can-float {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
        &.mat-form-field-should-float {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.  */
            .mat-form-field-label {
                transform: translateY(-1.1rem) scale(0.75) perspective(50px) translateZ(1px);

                &::before {
                    display: none;
                }
            }
        }
    }

    .mat-ripple-element {
        transition-duration: 0ms !important;
    }
}

.reviews-page-grid {
    .request-form-header {
        @include wpm-gradient-reverse();
    }
}

.mat-mdc-list .mat-mdc-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-item,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
    height: 3rem !important;
}

// for repeatable drag-drop
.dragable-row {
    /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.  */
    mat-input-container {
        padding-left: 1em;
    }

    .drag-handle {
        position: absolute;
        left: -35px;
        bottom: 7px;
    }
}

// modal for booster contact activities
.activity-modal {
    max-height: 60vh !important;
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.  */
    mat-dialog-container {
        max-height: 60vh !important;
        overflow: hidden !important;
    }
}

// end: for repeatable drag-drop

.onboarding-journey-dialog {
    max-width: 45rem !important;
    max-height: 89vh;
    border-radius: 1.25rem;

    .mat-mdc-dialog-container {
        border-radius: 1.25rem;
        overflow-y: visible;
        overflow-x: initial;
    }
}

.webcallback-dialog {
    .mat-mdc-dialog-container {
        border-radius: 1.25rem;
    }
}

.noscroll {
    overflow: hidden;
}

.gfb-modal {
    .mat-mdc-dialog-container {
        border-radius: 14px;
        overflow: hidden;
    }
}

.out-of-box,
.disconnect-box {
    width: 80%;
    max-width: 960px !important;
    max-height: 89vh;

    &.no-padding {
        width: 100% !important;
        max-width: 100% !important;

        .mat-mdc-dialog-container {
            padding: 0 !important;
        }
    }

    &.center-auto {
        margin: 5vh auto;
    }

    &.custom-width-588 {
        width: 588px !important;

        @include custom-width-768 {
            width: 90% !important;
        }
    }

    &.custom-width-700 {
        width: 700px !important;
    }

    @include custom-width-768 {
        &.custom-width-700 {
            width: 80% !important;
        }
    }

    @include small-width {
        width: 95% !important;

        &.review-dialog {
            max-height: 90vh;

            .mat-mdc-dialog-container {
                padding-bottom: 0 !important;
            }
        }

        &.custom-width-700 {
            width: 90% !important;
        }
    }

    @include media(xs) {
        max-height: 90vh;
    }

    &.custom-width-850 {
        width: 850px !important;
        max-height: 90vh;
        height: 100%;
    }

    @include custom-width-768 {
        &.custom-width-850 {
            width: 100% !important;
            max-height: 100vh !important;
            height: 100%;

            .mat-mdc-dialog-content,
            .mat-mdc-dialog-container {
                max-height: 100% !important;
                overflow-y: auto !important;
                overflow-x: hidden !important;
            }
        }
    }

    &.custom-width-800 {
        width: 800px !important;
    }

    @include custom-width-768 {
        &.custom-width-800 {
            width: 100% !important;
            max-height: 100vh !important;
            height: 100%;

            .mat-mdc-dialog-content,
            .mat-mdc-dialog-container {
                max-height: 100% !important;
                overflow-y: auto !important;
                overflow-x: hidden !important;
            }
        }
    }

    @include custom-width-768 {
        &.full-screen-white {
            width: 100% !important;
            max-height: 100vh !important;
            height: 100%;

            .mat-mdc-dialog-container {
                overflow-y: auto !important;
                overflow-x: hidden !important;
                border-radius: 0;
            }

            .mat-mdc-dialog-content {
                max-height: 100% !important;
                overflow: unset;
                background: $white;
            }
        }
    }

    &.custom-width-430 {
        width: 430px !important;
    }

    @include custom-width-768 {
        &.custom-width-430 {
            width: 100% !important;
            max-height: 100vh !important;

            .mat-mdc-dialog-content,
            .mat-mdc-dialog-container {
                max-height: 100% !important;
                overflow-y: auto !important;
                overflow-x: hidden !important;
            }
        }
    }

    &.review-iframe-dialog {
        max-height: 100%;

        .mat-mdc-dialog-container {
            padding: 0 !important;

            .dialog-common {
                padding: 0;
            }
        }
    }

    &.request-injectable-form {
        .mat-mdc-dialog-container {
            .close-icon {
                top: -90px;
            }

            .dialog-common {
                padding-top: unset;
            }
        }
    }

    &.request-form {
        max-width: none !important;

        .mat-mdc-dialog-container {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .close-icon {
                top: -2.5rem;
            }

            .dialog-common {
                padding-top: unset;
            }
        }
    }

    &.upsell-dialog {
        width: 40% !important;
        max-width: 640px !important;

        @include custom-width-1237 {
            width: 55% !important;
            max-width: 640px !important;
        }

        @include custom-width-991 {
            width: 65% !important;
            max-width: 640px !important;
        }

        @include custom-width-768 {
            width: 75% !important;
            max-width: 640px !important;
        }

        @include small-width {
            width: 95% !important;
            max-width: 640px !important;
        }
    }

    // TODO FOR COLD CONTENT
    &.cold-content-dialog {
        width: 40% !important;
        max-width: 640px !important;

        @include custom-width-1237 {
            width: 55% !important;
            max-width: 640px !important;
        }

        @include custom-width-991 {
            width: 65% !important;
            max-width: 640px !important;
        }

        @include custom-width-768 {
            width: 75% !important;
            max-width: 640px !important;
        }

        @include small-width {
            width: 95% !important;
            max-width: 640px !important;
        }

        &.dialog-restaurant-type {
            width: 50% !important;
            max-width: 680px !important;

            .custom-checkbox-holder {
                padding-bottom: 1em;

                .custom-checkbox {
                    width: 33.3%;

                    @include custom-width-540 {
                        width: 52%;
                        clear: both;
                        margin: 0 auto;
                    }
                    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.  */
                    .mat-checkbox-label {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            @include custom-width-1237 {
                width: 60% !important;
                max-width: 750px !important;
            }

            @include custom-width-991 {
                width: 80% !important;
            }

            @include custom-width-768 {
                width: 87.6% !important;
            }

            @include small-width {
                width: 95% !important;
                max-width: 640px !important;
            }
        }

        &.dialog-restaurant-budget,
        &.dialog-restaurant-ambiance,
        &.dialog-housing-budget,
        &.dialog-housing-notation {
            .multi-select-holder {
                .row {
                    width: 80%;
                    margin: 0 auto;
                    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.  */
                    .mat-checkbox-label {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    @include custom-width-1415 {
                        width: 86.7%;
                    }

                    @include custom-width-768 {
                        width: 90%;
                    }

                    @include small-width {
                        width: 100%;
                    }
                }
            }
        }

        &.dialog-restaurant-budget {
            .multi-select-holder {
                .row {
                    width: 100%;

                    @include small-width {
                        .custom-checkbox {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.dialog-housing-budget {
            .multi-select-holder {
                width: 50%;
                float: left;

                .selections-left {
                    display: none;
                }

                .multi-title {
                    display: block;
                    font-weight: bolder;
                    text-transform: uppercase;
                }

                .row {
                    width: 100%;

                    .custom-checkbox {
                        width: 100%;
                    }
                }
            }

            .repeatable-holder {
                float: left;
                width: 50%;
                padding: 0;
                position: relative;

                .single-title {
                    display: block;
                    font-weight: bolder;
                    text-transform: uppercase;
                }

                .single-input-container {
                    padding-left: 5px;
                }
            }

            .sep {
                float: left;
            }
        }

        &.dialog-housing-notation {
            .multi-select-holder {
                .row {
                    width: 100%;

                    .custom-checkbox {
                        width: 33.3%;
                    }
                }
            }
        }

        &.dialog-restaurant-guides,
        &.dialog-housing-guides {
            .custom-toggle-holder {
                .row {
                    padding-top: 0.3em;
                    width: 75%;
                    border-bottom: 1px solid $dialog-border;

                    @include custom-width-768 {
                        width: auto;
                    }

                    @include media(xs) {
                        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.  */
                        .mat-slide-toggle-bar,
                        .mat-slide-toggle-content {
                            position: relative !important;
                        }
                    }
                    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.  */
                    .mat-slide-toggle-bar {
                        position: absolute;
                        float: right;
                        right: 0;
                    }

                    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.  */
                    .mat-slide-toggle-content {
                        position: absolute;
                        float: left;
                        padding-left: 1em;
                        @include font-size(1);
                    }
                }
            }

            .repeatable-holder {
                width: 75%;
                margin: 0 auto;

                @include custom-width-768 {
                    width: 100%;
                }
            }
        }
    }

    &.dialog-restaurant-ambiance.template-T2 {
        .multi-select-holder {
            display: none !important;
        }
    }

    &.dialog-menus {
        .mat-mdc-dialog-container {
            max-height: 90vh;
            padding: 0;
        }
    }

    &.feature-activation-dialog {
        max-height: 100%;

        .mat-mdc-dialog-container {
            padding: 0 !important;

            .dialog-common {
                padding: 0;
            }
        }
    }

    &.request-resend-dialog {
        max-height: 100%;

        .mat-mdc-dialog-container {
            padding: 0 !important;

            .dialog-common {
                padding: 0;
            }
        }
    }
}

.video-player-dialog-coaching {
    max-width: 90vw !important;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: visible;
    }
}

.out-of-box.request-form {
    width: 55% !important;
    @include media(xs, sm, md) {
        width: 90% !important;
    }
    @include custom-width-800 {
        width: 95% !important;
    }
    @include custom-width-768 {
        width: 100% !important;
        height: 100% !important;
        max-height: 100vh;
    }
}

.out-of-box.request-form .mat-mdc-dialog-container {
    border-radius: 0.857rem !important;
    @include custom-width-768 {
        border-radius: 0 !important;
    }
}

.smooth-borders .mat-mdc-dialog-container .mdc-dialog__surface {
    --mdc-dialog-container-shape: 1.25rem;
}

.rounded-modal {
    .mat-mdc-dialog-container {
        border-radius: 1.25rem;
        width: 61.875rem;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: none;
    }

    .modal-action {
        display: flex;
        width: 100%;

        .actions {
            display: flex;
            width: 100%;

            .cancel {
                margin-right: 0.5rem;
            }
        }

        .flex-end {
            justify-content: flex-end;
        }

        .flex-center {
            justify-content: center;
        }
    }
}

.disconnect-box {
    height: fit-content !important;

    .dialog-common {
        padding: 2rem;
    }

    span {
        color: white;
    }
}

.rounded {
    .mat-mdc-dialog-container {
        border-radius: 1.25rem;
    }
}

// UP / DOWN buttons for number input type
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
    margin: 0;
}

.cdk-overlay-container {
    &.bigModal {
        overflow-y: scroll;
        background: rgba(0, 0, 0, 0.4);

        .cdk-overlay-dark-backdrop {
            background: none;
        }

        .mat-mdc-dialog-container {
            max-height: unset !important;
        }
    }

    .cdk-overlay-pane .mat-mdc-select-panel {
        max-height: 18rem;
    }

    .cdk-overlay-pane .email-subject-panel {
        margin-top: rem(20, 16) !important;
        width: rem(202, 16) !important;
        min-width: 0 !important;

        @include custom-width-768 {
            right: rem(90);
            position: absolute;
        }
    }

    .cdk-overlay-pane .sms-subject-panel {
        margin-top: rem(31, 16) !important;
        width: rem(202, 16) !important;
        min-width: 0 !important;

        @include custom-width-768 {
            right: rem(90);
            position: absolute;
        }
    }

    .cdk-overlay-pane .opening-hours-select {
        min-width: 7rem !important;

        .mat-mdc-option:first-child {
            position: sticky;
            z-index: 1;
            top: 0;
            background-color: white;
            box-shadow: 1px -2px 10px 0px grey;
        }
    }

    .cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            &.full-screen {
                max-width: 100vw !important;
                width: 100vw !important;
                height: 100vh !important;

                .mat-mdc-dialog-container {
                    max-width: 100vw;
                    max-height: 100vh;
                    padding: 0;
                    background: transparent !important;
                }
            }

            .mat-mdc-dialog-container {
                max-height: 95vh;
                min-height: 100%;

                @include small-width {
                    min-height: 100%;
                    max-width: 100vw;
                }
            }
        }
    }

    .autocomplete-address {
        position: relative;
        right: 13px;
        top: 10px;

        .selected-option {
            background-color: #cde9fe;
        }

        .inactive-val {
            color: #707070;
            text-transform: uppercase;
        }
    }

    .autocomplete-service-area-places {
        position: relative;
        right: 14px;
        top: 12px;
    }

    .phone-data-cell-select,
    .url-data-cell-select {
        position: absolute;
        top: 2.25rem;

        .mat-mdc-selected {
            background-color: #cde9fe !important;
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
            .mat-option-text {
                color: #0a68f1;
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.  */
        .mat-radio-label {
            margin-bottom: 0;
        }
    }
}

::ng-deep .mat-datepicker-popup {
    .mat-datepicker-content {
        .mat-calendar {
            @include small-width {
                width: 250px !important;
                height: 300px !important;
            }
        }
    }
}

.language-fr {
    .locations-table .tbody-desktop {
        top: 4.5em;
    }
}

.app-sidebar {
    display: none;
}

.nav-collapsed .app-page-container .app-content-wrapper .app-content {
    padding-left: 0px;
}

.pull-right {
    float: right;
}

.logoApp {
    width: 11em;
}

.app-box {
    background-color: #fff;
    border: 1px solid $grey-border;
    box-shadow: $box-shadow !important;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper,
.cdk-overlay-pane,
.mat-mdc-dialog-container {
    &:not(.solocal-popover):not(.solocal-select-dropdown) {
        transition: none !important;
        transform: none !important;
        animation: none !important;
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

// mat-option overrides reset (after new post form)
.default-option {
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.  */
    .mat-option-text {
        white-space: normal;
        height: auto;
        line-height: initial;
        padding: 0.75em 0;
        color: $black-alpha-87 !important;
    }
}

.actionBtn {
    color: $white;
    font-weight: bold;
    padding: 1rem 3rem;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: $white;
    }

    &:disabled {
        background: gray !important;
    }
}

.hasHeader {
    margin-top: 30px;
}

.iconePic {
    margin: auto;
    display: block !important;
    padding-top: 15px;
}

.app-content-wrapper,
.box-default {
    box-shadow: none;
}

.mat-mdc-list {
    background-color: $custom-mat-list-bg;
}

.starPar {
    display: inherit;
    margin-top: -90px;
    margin-right: 20px;
}

.starPar img {
    zoom: 140%;
}

.textPar {
    padding: 16px 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.  */
.mat-list-item-content {
    height: auto !important;
    padding: 16px 16px 0px 16px !important;
}

.responseComment {
    bottom: 22px !important;
    right: 40px;
    position: absolute;
}

.logoAuthorComment {
    position: relative;
    top: -3.25em;
    width: 4em !important;
    height: 4em !important;
}

.logoPostComment {
    top: -8em;
}

.iconeMoreGrey {
    color: $grey-color;
    top: 0.5em;
    position: absolute;
    right: 1em;
}

.box-body {
    padding: 0.5em;

    @include small-width {
        padding: 0.1em;
    }
}

.layer {
    margin-left: -0.5em;
    @include font-size(3.875);
    font-weight: 300;
    line-height: 0.4;
    text-align: left;
    color: $black-color;
}

.-avis {
    height: 25px;
    @include font-size(0.9375);
    font-weight: 500;
    line-height: 1.67;
    text-align: left;
    color: $black-color;
}

.Notes {
    height: 25px;
    font-weight: 600;
    @include font-size(0.9375);
    text-align: left;
    color: $black-color;
}

.sep {
    width: 100%;
    height: 1px;
    background-color: $custom-separator;
}

.sep3 {
    margin-left: 1.5em;
    width: auto;
}

.Rectangle {
    margin: 0;
    width: 100px;
    height: 10px;
    border-radius: 6px;
    background-color: $base-color;
}

.Dbut-publication {
    width: 136px;
    height: 20px;
    font-family: Poppins, sans-serif;
    @include font-size(0.8125);
    line-height: 1.54;
    text-align: left;
    color: $black-color;
    font-weight: 500;
}

md-chip {
    padding: 4px 8px;
    margin: -2px 2px;
    border-radius: 24px;
    margin-bottom: 1em;
}

md-dialog-container {
    width: 50vw !important;
    min-height: 20vw;
}

.textBackToLine {
    width: 90%;
    white-space: normal !important;
}

.noBorder {
    border: none !important;
}

.heightAuto {
    height: auto !important;
}

//mosaique
#container {
    width: 100%;
    max-width: 900px;
    height: 440px;
}

#container2 {
    width: 100%;
    max-width: 900px;
    height: 440px;
}

.cols {
    column-count: 4;
    column-gap: 2%;
    column-width: 23%;
}

.cols1 {
    column-count: 1;
    column-gap: 3%;
    column-width: 97%;
}

.cols2 {
    -moz-column-count: 2;
    -moz-column-gap: 3%;
    -moz-column-width: 47%;
    -webkit-column-count: 2;
    -webkit-column-gap: 3%;
    -webkit-column-width: 47%;
    column-count: 2;
    column-gap: 3%;
    column-width: 47%;
}

.stat-num {
    @include font-size(4.5);
    font-weight: 300;
    line-height: 66px;
}

.stat-desc {
    @include font-size(0.8125);
    font-weight: 600;
    line-height: 1.92;
    margin-bottom: 10px;
    display: block;
}

.titleHome {
    line-height: 25px;
    padding-left: 1em;
}

.percent {
    @include font-size(1.1875);
    font-weight: 500;
    line-height: 25px;
    position: absolute;
    margin-left: 10px;
}

.cdk-overlay-pane.out-of-box.request-injectable-form .mat-mdc-dialog-container {
    overflow: hidden;
    overflow-y: auto;
}

.cta-inner {
    padding: 1.875rem !important;
}

.subtitleHome {
    text-align: center;
    line-height: 25px;
}

.logoHome {
    width: 4em !important;
    height: 4em !important;
    border-radius: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.  */
.mat-radio-checked .mat-radio-outer-circle {
    border-color: $custom-radio-circle;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.  */
.mat-radio-inner-circle {
    background-color: $custom-radio-circle;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.  */
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $custom-checkbox-checked;
}

.cdk-overlay-pane.out-of-box .mat-mdc-dialog-container {
    overflow: initial;
    max-height: 95vh;
}

.cdk-overlay-backdrop.shadowed {
    background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-pane.help-slides {
    width: 45rem;
    height: 22.5rem;

    @include custom-width-900 {
        width: auto;
        max-width: 98vw !important;
    }

    @include custom-width-768 {
        width: 22.5rem;
        height: 45rem;
    }

    @include media(xs) {
        max-width: 98vw !important;
    }

    .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        overflow: unset;

        .dialog-common {
            padding: 0;
            margin: 0;

            .mat-mdc-dialog-content {
                padding: 0;
                margin: 0;

                @include custom-width-768 {
                    max-height: 98vh !important;
                }
            }
        }
    }
}

.cdk-overlay-pane.website-help-slides {
    width: 45rem;
    height: auto;

    .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        overflow: unset;
        max-height: 95vh !important;

        .dialog-common {
            padding: 0;
            margin: 0;

            .mat-mdc-dialog-content {
                padding: 0;
                margin: 0;
                max-height: 95vh !important;

                @include custom-width-768 {
                    max-height: 98vh !important;
                }
            }
        }
    }
}

.cdk-overlay-pane.height-set-256 {
    height: 16em !important;
}

.cdk-overlay-pane.width-unset {
    width: auto !important;
    max-width: none !important;
}

.cdk-overlay-pane.width-set-400 {
    width: 25em !important;
}

.cdk-overlay-pane.width-set-380 {
    width: auto !important;
}

.cdk-overlay-pane.width-set-500 {
    width: 31.25rem !important;
}

.cdk-overlay-pane.width-set-650 {
    width: 40.625rem !important;
}

.cdk-overlay-pane.width-set-678 {
    width: 42.375rem !important;
}

.cdk-overlay-pane.width-set-646 {
    width: 40.375rem !important;
}

.cdk-overlay-pane.width-set-960 {
    max-width: 60rem !important;

    @include large-width {
        max-width: 50rem !important;
    }
}

.cdk-overlay-pane.upsell-call-dialog {
    width: 40rem;
}

.cdk-overlay-pane.out-of-box.dialog-sm {
    width: 25rem !important;
}

.cdk-overlay-pane.no-paddings .mat-mdc-dialog-container {
    padding: 0;
}

.cdk-overlay-pane.no-background .mat-mdc-dialog-container {
    background: none;
    box-shadow: none;
}

.cdk-overlay-pane.comment-retention-dialog {
    .mat-mdc-dialog-container {
        width: 22vw !important;
        border-radius: 20px;
    }
}

.cdk-overlay-pane.large.gbm-dialog {
    @media (max-width: 480px) {
        max-width: 100vw !important;
        width: 100%;
        height: 100vh;

        .mat-mdc-dialog-container {
            padding: 1.15rem;
            border-radius: unset;
            max-height: 100vh;
            overflow-x: hidden;
        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 95vw !important;
        width: 100%;

        .mat-mdc-dialog-container {
            padding: 2.25rem;
            overflow-x: hidden;
        }
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
        .mat-mdc-dialog-container {
            max-height: 85vh;
        }
    }
}

.dialog-common {
    position: relative;

    .dialog-photo-holder {
        max-height: 85vh;
        overflow: hidden;

        img {
            display: block;
            position: relative;
            height: 85vh;
            min-width: 100%;
            min-height: 100%;
            object-fit: contain;
            object-position: center;

            &.send-icon {
                width: auto;
            }
        }
    }

    .dialog-common-title {
        text-align: center;
        display: block;
        margin-bottom: 0.6em;
    }

    .dialog-common-help {
        color: black;
        text-align: left;
        font-size: medium !important;

        a {
            color: $solocal-blue !important;
            font-weight: normal !important;
        }

        a:link {
            text-decoration: underline !important;
        }

        a:hover {
            text-decoration: none !important;
        }
    }

    padding: 10px 0 0;

    .dialog-common-close {
        position: absolute;
        color: $dialog-common-close;
        right: -3rem;
        top: -3rem;
        border: 2px solid $dialog-common-close;
        border-radius: 50%;
        padding: 0.2em;
        @include font-size(1.5);
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: rotateZ(90deg);
            background: rgba(255, 255, 255, 0.2);
        }

        @include small-width {
            right: -25px;
            top: -68px;
        }
    }

    .mat-mdc-dialog-content {
        max-height: 65vh;
        overflow: auto;

        @include small-height {
            max-height: 45vh;
        }

        @include custom-width-540 {
            margin: 0 -10px;
            padding: 0 10px;
        }

        @include small-width {
            padding: 0 17px;
        }
    }

    &.review-dialog {
        @include media(xs) {
            overflow: auto;
        }

        .dialog-common-close {
            right: -39px;
            top: -39px;

            @include small-width {
                right: -10px;
                top: -54px;
                border: 2px solid $dialog-common-close;
            }
        }
    }

    .mat-mdc-raised-button {
        &.mat-primary {
            background-color: $base-color;
            padding: 0.5em 3.5em;
            border-radius: 5px;
            @include font-size(1);
        }
    }

    .dialog-action {
        display: block;
        margin-left: auto;
    }
}

.review-dialog .mat-mdc-dialog-container {
    padding: 0;
}

.cdk-overlay-pane {
    &.out-of-box {
        .mat-mdc-dialog-surface {
            overflow: visible;
        }

        &.dialog-opening-hours,
        &.dialog-specific-opening-hours {
            @include small-width {
                overflow: auto;
                max-height: 95vh;
            }
        }
    }
}

.cdk-overlay-pane.carousel .mat-mdc-dialog-container {
    background: none;
    box-shadow: none;
    overflow: initial;
    padding: 0;
}

.carousel-backdrop {
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.85) !important;
}

.typeChartBtn {
    float: right;
    z-index: 2;
    margin: 3em 12em 3em -4em !important;
}

.galleryPic:nth-child(3) img {
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
    -moz-filter: brightness(50%);
    -o-filter: brightness(50%);
}

.mask {
    position: absolute;
    top: 0em;
    left: 3em;
    line-height: 5em;
}

.overlay {
    height: 2.5em;
    position: absolute;
    background-color: $black;
    width: 100%;
}

.logoPayment {
    width: 4em;
    height: 3em;
}

.noRadius {
    border-radius: 0% !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.  */
.mat-list-text {
    display: block !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.  */
.listeUniforme .mat-list-item-content {
    padding: 4px 20px !important;
}

.zoom1_25 {
    zoom: 1.25;
}

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: $grey-color;
    text-align: center;
    height: 1.5em;
    margin: 2em 0;

    &:before {
        content: '';
        background: linear-gradient(to right, #d8dbe1, #d8dbe1, #d8dbe1);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }

    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        padding: 0 0.5em;
        line-height: 1.5em;
        color: $grey-color;
        background-color: $body-bg;
    }
}

.blueBorderTop {
    border-top: 10px solid $base-color;
}

.logoLogin {
    max-height: 2.5em;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.maxWidth45 {
    max-width: 45em !important;
    overflow: hidden;
}

.inputInvisible {
    border: none !important;
    background-color: $transparent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.  */
.activityItem .mat-list-item-content,
.activityItemNotFound .mat-list-item-content {
    display: block !important;
    padding-top: 0 !important;
    margin: 0 5em;
}

.mat-mdc-chip .mat-primary {
    color: $custom-chip !important;
    background-color: $custom-chip-bg !important;
}

.mat-mdc-raised-button .mat-primary {
    background-color: $base-color !important;
}

.mat-mdc-list .activityItem,
.mat-list[dense] .activityItem .mat-list-item-content {
    @include font-size(0.9375);
    color: $black-color !important;
    font-family: 'Poppins', sans-serif !important;
}

.mat-mdc-list .activityItemNotFound,
.mat-list[dense] .activityItemNotFound .mat-list-item-content {
    @include font-size(0.9375);
    color: $grey-color !important;
    font-family: 'Poppins', sans-serif !important;
}

.loading {
    height: 5em !important;
}

.loading path {
    stroke: $base-color !important;
    stroke-width: 4 !important;
}

.pointer {
    cursor: pointer;
}

.disabledChip {
    border: solid 2px $blue-alpha-10;
    color: $base-color !important;
    background-color: $white !important;
}

.picPost {
    max-height: 200px;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    display: inline-block;
    width: 100%;
    height: 200px;
    cursor: pointer;
}

.inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.picGallery {
    min-height: 12em;
    max-height: 12em;
}

.logoListReview {
    width: 2em;
    padding: 2px;
}

.statistics-tooltip {
    min-height: 30px;
    color: $stats-tooltip;
    text-align: center;
    text-transform: capitalize;

    .custom-tooltip-title {
        @include font-size(0.9375);
        font-weight: 600;
    }
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    user-select: none;
    /* Non-prefixed version, currently
                                 supported by Chrome, Opera and Firefox */
}

// overwrite profile page containers based on upper class
.has-location-selector {
    app-my-profile-page {
        .container {
            height: calc(100vh - #{$header-menu-height} - #{$breadcrumbs-height} - 5px);
        }

        .tab-child-container {
            height: calc(100vh - #{$header-menu-height} - (#{$breadcrumbs-height} + 2rem) - 5px);
        }

        @include small-width {
            .container {
                height: auto;
            }

            .tab-child-container {
                padding: 0 0.8rem;
                height: auto;
                overflow: hidden;
            }
        }
    }
}

.pac-container {
    z-index: 9999;
}

@include custom-width-768 {
    .embeddedServiceHelpButton,
    .inbenta-bot__launcher {
        display: none !important;
    }
}

slm-slide-in-container {
    .embeddedServiceHelpButton,
    .inbenta-bot__launcher {
        display: none !important;
    }
}

.review-request-custom-popupmenu {
    background-color: #39bcf8 !important;
    min-height: 30px !important;
    margin-bottom: 8px;
    margin-left: 40px;

    .mat-mdc-menu-content:not(:empty) {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .mat-mdc-menu-item {
        color: #fff;
        text-align: center;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
    }
}

slm-generic-banner {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.  */
    .mat-button-focus-overlay {
        background-color: transparent !important;
    }
}

// Video player
.video-player-dialog .mat-mdc-dialog-container {
    padding: 40px;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .video-player-dialog {
        max-width: none !important;

        .mat-mdc-dialog-container {
            width: 100vw !important;
            max-width: none !important;
            height: 100vh;
            padding: 0;
            border-radius: 0;
            overflow: hidden;
        }

        .dialog-close {
            right: 5px !important;
            top: 5px !important;
        }
    }

    @media only screen and (orientation: portrait) {
        .video-player-dialog {
            transform: rotate(90deg) !important;

            .mat-mdc-dialog-container {
                width: 100vh !important;
                height: 100vw;
            }
        }
    }
}

.improve-visibility-dialog {
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.  */
    mat-dialog-container {
        border-radius: 1.25rem;
        padding: 1.75rem 2rem;
    }

    @include custom-width(600) {
        .title {
            margin-bottom: 7rem;
        }
    }

    solocal-progress-bar {
        .progress-bar-fill {
            background: transparent linear-gradient(90deg, #ffaf1480 0%, #ffaf148e 31%, #ff9114 100%) 0 0 no-repeat
                padding-box;
        }

        @include custom-width(600) {
            .title {
                margin-bottom: 1rem;
            }
        }
    }
}

//Location information page

.location-tile {
    $bUIld-box-shadow: 0 5px 10px rgba(24, 25, 26, 0.1);
    background: #fff;
    border: 1px solid $grey-border;
    border-radius: $radius-3;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: $bUIld-box-shadow;
    }

    &__title {
        color: mat.m2-get-color-from-palette($solocal-grey-palette, '900');
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0.025rem;
    }

    &__action-icon {
        margin-bottom: $spacing-4;
        display: inline;
        float: right;

        slm-restricted-access-icon {
            .img-locked {
                position: relative;
                bottom: $spacing-1;
                margin-left: $spacing-1;
            }
        }
    }

    &__text {
        color: black;
        font-size: 0.875rem;
        font-weight: normal;
    }

    &__placeholder {
        color: mat.m2-get-color-from-palette($solocal-grey-palette, '400');
        font-size: 0.875rem;
        font-weight: normal;
    }

    margin-bottom: $spacing-4;
}

.location-info-tile-title {
    color: mat.m2-get-color-from-palette($solocal-grey-palette, '900');
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.025rem;
}

.location-info-item-title {
    color: mat.m2-get-color-from-palette($solocal-grey-palette, '900');
    font-weight: bold;
}

.location-info-item-text {
    color: black;
    font-size: 14px;
    font-weight: normal;
}

.location-info-item-placeholder {
    color: mat.m2-get-color-from-palette($solocal-grey-palette, '400');
    font-size: 14px;
    font-weight: normal;
}

.limited-access-dialog-container {
    .mat-mdc-dialog-container {
        width: 25rem;
        padding: 2.563rem 1.875rem 1.875rem 1.875rem;
        height: 18.75rem;
    }
}

.profile-pass-editor {
    .form-field .small.error .material-icons {
        @include small-width {
            margin-right: 8.4rem;
        }
    }

    @include small-width {
        .form-field {
            width: 100%;
        }
    }
}

.connection-identifier {
    @include small-width {
        .form-field {
            display: inline;
        }
    }
}

.mat-mdc-tooltip.info-tooltip-email {
    position: relative;
    max-width: unset !important;
    display: block;
    background-color: #6e7075;
    opacity: 1;
    overflow: initial;
    margin: 0.3rem;
    color: white;
    font-size: 0.75rem;

    &:after {
        top: -0.3rem;
        right: calc(50% - 0.2rem);
        transform: rotate(0);
        width: 0;
        height: 0;
        content: '';
        position: absolute;
        border-left: 0.2rem solid transparent;
        border-right: 0.2rem solid transparent;
        border-bottom: 0.3rem solid #6e7075;
    }

    @include media(xs, sm) {
        max-width: 18.75rem !important;
        &:after {
            right: calc(66% - 0.2rem);
        }
    }
}

.cdk-overlay-container .footer-dialog .mat-mdc-dialog-container {
    border-radius: 0.75rem;
    padding: 0.75rem;
}

.solocal-header-sticky {
    position: sticky;
    top: 0;
    z-index: 500;
}

.solocal-header-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;

    .solocal-header-page-actions {
        display: flex;
        flex-direction: row-reverse;
        gap: 0.75rem;
        margin-left: auto; // position page buttons of right hand side of screen
    }

    .solocal-header-page-totals {
        font-weight: 600;
    }

    slm-managed-location-selector {
        flex-grow: 1;
        min-width: 29.625rem;
        max-width: 29.625rem;

        @include media(xs, sm) {
            min-width: unset;
            width: 100%;
        }
    }

    solocal-filter-button {
        margin-left: auto;
    }

    slm-page-filters {
        margin-left: auto;
        margin-top: auto;
    }
}

.photo-dialog-width {
    @include media(lg, xl, md) {
        width: 60vw;
    }
}

.video-manage-dialog {
    overflow: hidden;
    @include media(lg, xl, md) {
        width: 50vw;
    }
}

.modify-keywords-dialog {
    width: 76rem;
    --mat-dialog-container-max-width: 90vw;
}

.slm-action-clickable:hover {
    cursor: pointer;
}

.intercom-namespace > .intercom-app > iframe {
    @include media(xs, sm, md) {
        bottom: 6rem;
    }
}
