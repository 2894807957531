@use "sass:math";
@import 'variables';
@import "node_modules/@shared/ui-components/lib/styles/mixins";
@import "breakpoints-deprecated";

@mixin app-content {
    padding: 0 2rem !important;
    width: 100% !important;

    @include media(xs, sm) {
        padding: 0 0.5rem !important;
    }
    @include small-width() {
        padding: 0 1rem !important;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin vcenter-absolute() {
    top: 50%;
    transform: translateY(-50%);
}

@mixin vbottom-absolute($offset: -50%) {
    top: 100%;
    transform: translateY($offset);
}

@mixin xy-centered-absolute() {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@mixin wpm-gfb-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, #00afff 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, $main-grad-stop 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient-reverse($startColor: $main-grad-start, $endColor: $main-grad-stop) {
    background: $endColor !important;
    background: -moz-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: -webkit-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: linear-gradient(to right, $endColor 0%, $startColor 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor, endColorstr=$endColor, GradientType=1) !important;
}

// REM => PX font size fall down
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 16) + px;
    font-size: $sizeValue + rem;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin grid-child($col-start, $col-end, $row-start, $row-end) {
    grid-column: #{$col-start}/#{$col-end};
    grid-row: #{$row-start}/#{$row-end};
}

@mixin wpm-button {
    border-radius: 0;
    @include font-size(0.875);
    text-align: center;
    min-width: 7.5rem;
    cursor: pointer;
    font-weight: 600;

    &[disabled] {
        cursor: not-allowed;
        filter: grayscale(100%);
    }
}

@mixin wpm-button-flat {
    @include wpm-button;
    @include wpm-gradient-reverse;
    padding: 0.3rem 2.5rem;
    border: none;
    color: white;
    transition: background-color 0.2s;

    // &:not([disabled]):hover {
    //     background: black !important;
    // }
}

@mixin wpm-button-stroked {
    @include wpm-button;
    color: #006eff;
    border: 2px solid #006eff;
    padding: calc(1rem - 1px) 1.5rem;
    background: none;
    transition: border-color 0.2s;
}

@mixin dpr1_25 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx),
    only screen and (min-resolution: 120dpi) {
        @content;
    }
}

@mixin dpr1_5 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 1.5dppx),
    only screen and (min-resolution: 144dpi) {
        @content;
    }
}

@mixin outline($width: 4px) {
    outline: $width solid rgba($main-color, 0.5) !important;
}

@mixin outline-inset($width: 4px) {
    @include outline($width);
    outline-offset: -$width;
}
